import {useDispatch} from 'react-redux';
import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {MdOutlineMenu} from 'react-icons/md';

import DesktopHeader from '../components/DesktopHeader';
import axios from '../axios';
import {updateShowDrawerMenu} from '../redux/slices/generalSlice';
import Loading from '../components/Loading';
import Drawer from '../components/Drawer';
import DataTable from 'react-data-table-component';
import {get} from '../helpers';

export default function Vehicles() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortDirection, setSortDirection] = useState('none');
  const [sortField, setSortField] = useState('none');

  const dispatch = useDispatch();
  const fetchVehicles = useCallback(
      async (page, perPage, sortColumn = 'none', sortDirection = 'none') => {
        setLoading(true);
        try {
          const response = await axios.get(
              `/admin/vehicles?page=${page}&per_page=${perPage}&sort_column=${sortColumn}&sort_direction=${sortDirection}`);
          const vehicles = get(response, 'vehicles', []);
          const count = get(response, 'count', 0);
          // console.log(response);

          setData(vehicles);
          setTotalRows(count);
        } catch (e) {
          console.log(e.message);
        }
        setLoading(false);
      }, []);

  useEffect(() => {
    fetchVehicles(page, perPage, sortField, sortDirection).then(r => {
    });
  }, [fetchVehicles, page, perPage, sortField, sortDirection]);

  const columns = [
    {
      name: 'Title',
      selector: row => get(row, 'name', 'unknown'),
      sortable: true,
      sortField: 'name',
    },
    {
      name: 'IMEI',
      selector: row => get(row, 'imei', 'unknown'),
      sortable: true,
      sortField: 'imei',
    },
    {
      name: 'Phone Number',
      selector: row => get(row, 'phone_number', '-'),
      sortable: true,
      sortField: 'phone_number',
    },
    {
      name: 'Model',
      selector: row => get(row, 'modal', 'unknown'),
      sortable: true,
      sortField: 'modal',
    },
    {
      name: 'Activated',
      selector: row => {
        return new Date(get(row, 'activated_at',
            new Date().toISOString())).toLocaleDateString();
      },
      sortable: true,
      sortField: 'activated_at',
    },
    {
      name: 'Expired',
      selector: row => {
        return new Date(get(row, 'expired_at',
            new Date().toISOString())).toLocaleDateString();
      },
      sortable: true,
      sortField: 'expired_at',
    },
    {
      name: 'Last Request',
      selector: row => {
        return new Date(get(row, 'last_request_at',
            new Date().toISOString())).toLocaleString();
      },
      sortable: true,
      sortField: 'last_request_at',
    },
    {
      name: 'Status',
      selector: row => get(row, 'status', '-'),
      sortable: true,
      sortField: 'status',
    },
    {
      name: 'Action',
      selector: row => {
        return <button onClick={() => {
          deleteVehicle(row.imei).then(r => {
          });
        }}>Delete</button>;
      },
      sortable: true,
      sortField: 'last_request_at',
    },
  ];

  async function deleteVehicle(id) {
    setLoading(true);
    try {
      await axios.delete(`/admin/vehicle/${id}`);

      const newVehicles = [...data];
      const vehicles = newVehicles.filter((row) => {
        return row.imei !== id;
      });

      setData(vehicles);
    } catch (e) {
      console.log(e.message);
    }
    setLoading(false);
  }

  return (
      <div className="flex md:flex-row flex-col h-full relative">
        <div className="bg-blue-950 w-16 md:block hidden">
          <DesktopHeader/>
        </div>
        <div>
          <div
              className="w-full bg-white shadow md:hidden block flex justify-between items-center">
            <button
                className="cursor-pointer px-2 py-4 w-12 z-10"
                onClick={() => {
                  dispatch(updateShowDrawerMenu(true));
                }}
            >
              <MdOutlineMenu size={24} color="#001C6F"/>
            </button>
            <div className="w-full z-0 absolute">
              <div className="text-blue-950 text-base font-bold text-center">
                Vehicles
              </div>
            </div>
            <button
                className="px-4 py-2 mr-2 z-10 ml-5 bg-blue-950 rounded-2xl text-center text-white font-normal text-sm font-bold"
                onClick={() => {
                  return navigate('/add-vehicle');
                }}
            >
              Add +
            </button>
          </div>
        </div>
        <div className="flex-1 relative p-5 table-listing">
          <div
              className="text-blue-950 text-xl font-bold md:mb-5 md:block hidden">
            Vehicles

            <button
                className="px-4 py-1 mr-2 z-10 ml-5 bg-blue-950 rounded-2xl text-center text-white font-normal text-sm font-bold"
                onClick={() => {
                  return navigate('/add-vehicle');
                }}
            >
              Add New +
            </button>
          </div>
          <DataTable
              fixedHeader={true}
              columns={columns}
              data={data}
              progressPending={loading}
              pagination
              paginationServer
              sortServer
              onSort={(column, sortDirection) => {
                setSortField(column.sortField);
                setSortDirection(sortDirection);
              }}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={(newPerPage) => {
                setPerPage(newPerPage);
              }}
              onChangePage={(page) => {
                setPage(page);
              }}
          />
        </div>
        {loading && <Loading/>}
        <Drawer/>
      </div>
  );
}
