import React, {useState} from 'react';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import axios from '../axios';
import {get} from '../helpers';

const UserSearchAndSelect = ({setUsers}) => {
  //set default query terms
  const [query, setQuery] = useState('');

  //get animated components wrapper
  const animatedComponents = makeAnimated();

  // fetch filters search results for dropdown
  const loadOptions = async () => {
    if (query.length < 3) {
      return [];
    }
    const response = await axios.get(`/admin/search-user?q=${query}`);

    return get(response, 'users', []);
  };

  return (
      <AsyncSelect
          cacheOptions
          isMulti
          components={animatedComponents}
          getOptionLabel={(e) => {
            return `${e.display_name} (${e.username})`;
          }}
          getOptionValue={(e) => e.id}
          loadOptions={loadOptions}
          onKeyDown={(event) => {
            setQuery(event.target.value);
          }}
          onChange={(value) => setUsers(value)}
      />
  );
};

export default UserSearchAndSelect;
