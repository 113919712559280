import {useDispatch} from 'react-redux';
import React, {useState} from 'react';
import {toast} from 'react-toastify';

import Popup from './Popup';
import {
  updateLoading,
} from '../redux/slices/generalSlice';
import axios from '../axios';
import {get, set} from '../helpers';

const vehicleInitialData = {
  "username": "",
  "password": "12345",
  "role": "manager",
  "displayName": "",
  "email": "",
  "contactNumber": "",
  "note": "",
  "address": "",
  "notification_settings" : {
    "ignition_on": true,
    "ignition_off": true,
    "offline": true,
    "over_speed": true
  }
};

export default function CreateUserPopup({show, onClose}) {
  const [userInfo, setUserInfo] = useState(vehicleInitialData);

  const updateUserInfo = (column, value) => {
    setUserInfo((vInfo) => {
      const newUSerInfo = {...vInfo};
      set(newUSerInfo, column, value);
      return newUSerInfo;
    });
  };

  const dispatch = useDispatch();

  const createUser = (e) => {
    e.preventDefault();
    dispatch(updateLoading(true));
    axios.post(`/admin/create-user`, userInfo)
    .then((response) => {
      if (get(response, 'success')) {
        toast.success('User created successfully!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setUserInfo(vehicleInitialData)
      }
      dispatch(updateLoading(false));
    })
    .catch((e) => {
      console.log(e.message);
      dispatch(updateLoading(false));
      toast.error('User create failed!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    });
  };

  return (
      <Popup title="Create User" show={show} onClose={onClose}>
        <form onSubmit={createUser}>
          <div className="w-full">
            <div className="mt-7">
              <div
                  className="text-zinc-500 text-base font-normal mb-2 w-full font-bold">
                Username *
              </div>
              <div>
                <input
                    className="w-full bg-white rounded-md shadow border border-neutral-200 p-2 text-zinc-500 text-base font-semibold"
                    placeholder="Example: 9994888117"
                    value={get(userInfo, 'username', '')}
                    required
                    type="text"
                    onChange={(e) => {
                      updateUserInfo('username', e.target.value);
                    }}
                />
              </div>
            </div>
            <div className="mt-7">
              <div
                  className="text-zinc-500 text-base font-normal mb-2 w-full font-bold">
                Display name *
              </div>
              <div>
                <input
                    className="w-full bg-white rounded-md shadow border border-neutral-200 p-2 text-zinc-500 text-base font-semibold"
                    placeholder="Example: Mr. Gowtham Kumar"
                    value={get(userInfo, 'displayName', '')}
                    required
                    type="text"
                    onChange={(e) => {
                      updateUserInfo('displayName', e.target.value);
                    }}
                />
              </div>
            </div>
            <div className="mt-7">
              <div
                  className="text-zinc-500 text-base font-normal mb-2 w-full font-bold">
                Contact Number *
              </div>
              <div>
                <input
                    className="w-full bg-white rounded-md shadow border border-neutral-200 p-2 text-zinc-500 text-base font-semibold"
                    placeholder="Example: 9994888117"
                    value={get(userInfo, 'contactNumber', '')}
                    required
                    type="text"
                    onChange={(e) => {
                      updateUserInfo('contactNumber', e.target.value);
                    }}
                />
              </div>
            </div>
            <div className="mt-7">
              <div
                  className="text-zinc-500 text-base font-normal mb-2 w-full font-bold">
                Email
              </div>
              <div>
                <input
                    className="w-full bg-white rounded-md shadow border border-neutral-200 p-2 text-zinc-500 text-base font-semibold"
                    placeholder="Example: gowtham@gmail.com"
                    value={get(userInfo, 'email', '')}
                    type="email"
                    onChange={(e) => {
                      updateUserInfo('email', e.target.value);
                    }}
                />
              </div>
            </div>
            <div className="mt-7">
              <div
                  className="text-zinc-500 text-base font-normal mb-2 w-full font-bold">
                Address
              </div>
              <div>
                <textarea
                    className="w-full bg-white rounded-md shadow border border-neutral-200 p-2 text-zinc-500 text-base font-semibold"
                    placeholder=""
                    value={get(userInfo, 'address', '')}
                    onChange={(e) => {
                      updateUserInfo('address', e.target.value);
                    }}
                />
              </div>
            </div>
            <div className="mt-7">
              <div
                  className="text-zinc-500 text-base font-normal mb-2 w-full font-bold">
                Note
              </div>
              <div>
                <textarea
                    className="w-full bg-white rounded-md shadow border border-neutral-200 p-2 text-zinc-500 text-base font-semibold"
                    placeholder=""
                    value={get(userInfo, 'note', '')}
                    onChange={(e) => {
                      updateUserInfo('note', e.target.value);
                    }}
                />
              </div>
            </div>
          </div>
          <div className="mt-5 mb-2 text-center">
            <button
                className="bg-blue-950 rounded-2xl text-center text-white text-base font-normal py-2 px-5 mx-2 font-bold"
                onClick={() => {
                }}
            >
              Save
            </button>
          </div>
        </form>
      </Popup>
  );
}
