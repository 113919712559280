import {MdOutlineMenu} from 'react-icons/md';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {toast} from 'react-toastify';

import DesktopHeader from '../components/DesktopHeader';
import {updateShowDrawerMenu} from '../redux/slices/generalSlice';
import Loading from '../components/Loading';
import Drawer from '../components/Drawer';
import axios from '../axios';
import {get, set} from '../helpers';
import UserSearchAndSelect from '../components/UserSearchAndSelect';

const vehicleInitialData = {
  'imei': '',
  'name': '',
  'phone_number': '',
  'type': 'truck',
  'modal': 'concox-v5', //teltonika-fmb120,concox-v5,teltonika-fmb920,teltonika-fmb125
  'group': 'Bikes',
  'notification': 'none',
  'speed_limit': 60,
  'driver': {
    'name': '',
    'phone': '',
  },
  'fuel_type': 'petrol', //petrol, diesel
  'fuel_calculation_type': 'none', //litre, voltage, none
  'fuel_calculation_values': {
    'a0': 0,
    'a1': 0,
    'a2': 0,
    'a3': 0,
  },
  'fuel_tank_capacity': 25,
  'fuel_price': 103,
  'terminal': {
    'ignition': false,
    'plugged': true,
    'relay': false,
    'signal_strength': 'no_signal',
    'battery_level': 'high',
    'external_voltage': 0,
    'odometer': 0,
    'status': 'parked', //parked,moving,idle,offline
    'engine_running': false,
    'engine_hours': 0,
    'engine_type': 'none', // none,ac,engine
    'engine_on_at': null,
    'last_request_at': '2023-10-20T04:39:05.689Z',
    'started_at': null,
    'ended_at': '2023-10-20T04:39:05.689Z',
    'moving_started_at': null,
    'moving_ended_at': null,
    'fuel': 0,
    'last_location': {
      'latitude': 0,
      'longtitude': 0,
      'altitude': 0,
      'course': 0,
      'speed': 0,
      'timestamp': '2023-10-20T04:39:05.689Z',
      'address': 'Unknown Address (Drive for a km to auto configure tracker)',
    },
  },
  'trip_summary': {
    'date': null,
    'distance_traveled': 0,
    'engine_hours': 0,
    'max_speed': 0,
    'total_speed': 0,
    'total_speed_points': 0,
    'average_speed': 0,
    'over_speed_distance': 0,
    'primary_engine_hours': 0,
    'moving_hours': 0,
    'fuel_consumed': 0,
    'fuel_usage_cost': 0,
  },
  'status': 'parked',
  'last_request_at': '2023-10-20T04:39:05.689Z',
};

export default function AddVehicle() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState('');
  const [users, setUsers] = useState([]);
  const [vehicleInfo, setVehicleInfo] = useState(vehicleInitialData);

  const updateVehicleInfo = (column, value) => {
    setVehicleInfo((vInfo) => {
      const newVehicleInfo = {...vInfo};
      set(newVehicleInfo, column, value);
      return newVehicleInfo;
    });
  };

  const addVehicle = (e) => {
    e.preventDefault();
    if (users.length <= 0) {
      toast.error('Select at-least one user to assign the vehicles ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      return;
    }

    setLoading(true);
    axios.post('/admin/create-vehicle', {
      vehicle: vehicleInfo,
      users: users,
    }).then((response) => {
      if (response?.success) {
        toast.success(response.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setVehicleInfo(vehicleInitialData);
      }
      setLoading(false);
    }).catch((e) => {
      let errorMessage = get(e, 'response.data.message', null);
      setError(errorMessage);
      toast.error(errorMessage, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      setLoading(false);
    });
  };

  return (
      <div className="flex md:flex-row flex-col h-full relative">
        <div className="bg-blue-950 w-16 md:block hidden">
          <DesktopHeader/>
        </div>
        <div>
          <div
              className="w-full bg-white shadow md:hidden block flex justify-between items-center">
            <button
                className="cursor-pointer px-2 py-4 w-12 z-10"
                onClick={() => {
                  dispatch(updateShowDrawerMenu(true));
                }}
            >
              <MdOutlineMenu size={24} color="#001C6F"/>
            </button>
            <div className="w-full z-0 absolute">
              <div className="text-blue-950 text-base font-bold text-center">
                Add Vehicle
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 relative p-6 overflow-y-auto">
          <div className="w-full">
            <form onSubmit={addVehicle}>
              <div className="w-full">
                <fieldset className="p-4 border-2 rounded border-neutral-100">
                  <legend className="text-blue-950 font-bold px-4">
                    Device:
                  </legend>
                  <div className="grid md:grid-cols-3 grid-cols-1 gap-4">
                    <div className="mt-4">
                      <div
                          className="text-neutral-800 text-lg font-medium break-words mb-3 w-full">
                        IMEI
                      </div>
                      <div className="relative">
                        <input
                            className="w-full bg-white rounded-md shadow border border-neutral-200 p-4 text-neutral-800 text-base font-normal"
                            placeholder="352016701979361"
                            value={get(vehicleInfo, 'imei', '')}
                            required
                            type="text"
                            onChange={(e) => {
                              setError(null);
                              updateVehicleInfo('imei', e.target.value);
                            }}
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <div
                          className="text-neutral-800 text-lg font-medium break-words mb-3 w-full">
                        Phone Number
                      </div>
                      <div className="relative">
                        <input
                            className="w-full bg-white rounded-md shadow border border-neutral-200 p-4 text-neutral-800 text-base font-normal"
                            placeholder="9994888117"
                            value={get(vehicleInfo, 'phone_number', '')}
                            required
                            type="text"
                            onChange={(e) => {
                              setError(null);
                              updateVehicleInfo('phone_number', e.target.value);
                            }}
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <div
                          className="text-neutral-800 text-lg font-medium break-words mb-3 w-full">
                        Model
                      </div>
                      <div className="relative">
                        <select
                            className="w-full bg-white rounded-md shadow border border-neutral-200 p-4 text-neutral-800 text-base font-normal"
                            required
                            value={get(vehicleInfo, 'modal', '')}
                            onChange={(e) => {
                              setError(null);
                              updateVehicleInfo('terminal.engine_type', 'none');
                              updateVehicleInfo('modal', e.target.value);
                            }}
                        >
                          <option value="concox-v5">Concox V5</option>
                          <option value="teltonika-fmb120">Teltonika FMB120
                          </option>
                          <option value="teltonika-fmb125">Teltonika FMB125
                          </option>
                          <option value="teltonika-fmb920">Teltonika FMB920
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <fieldset
                    className="p-4 border-2 rounded border-neutral-100 mt-5">
                  <legend className="text-blue-950 font-bold px-4">
                    Managers:
                  </legend>
                  <div className="w-full">
                    <div className="mt-4">
                      <UserSearchAndSelect setUsers={(newUsers) => {
                        const userList = [];
                        newUsers.forEach((userInfo) => {
                          userList.push(userInfo.id);
                        });
                        console.log(userList);
                        setUsers(userList);
                      }}/>
                    </div>
                  </div>
                </fieldset>
                <fieldset
                    className="p-4 border-2 rounded border-neutral-100 mt-5">
                  <legend className="text-blue-950 font-bold px-4">
                    Vehicle:
                  </legend>
                  <div className="grid md:grid-cols-3 grid-cols-1 gap-4">
                    <div className="mt-4">
                      <div
                          className="text-neutral-800 text-lg font-medium break-words mb-3 w-full">
                        Name
                      </div>
                      <div className="relative">
                        <input
                            className="w-full bg-white rounded-md shadow border border-neutral-200 p-4 text-neutral-800 text-base font-normal"
                            placeholder="TN42AY5767"
                            value={get(vehicleInfo, 'name', '')}
                            required
                            type="text"
                            onChange={(e) => {
                              setError(null);
                              updateVehicleInfo('name', e.target.value);
                            }}
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <div
                          className="text-neutral-800 text-lg font-medium break-words mb-3 w-full">
                        Odometer
                      </div>
                      <div className="relative">
                        <input
                            className="w-full bg-white rounded-md shadow border border-neutral-200 p-4 text-neutral-800 text-base font-normal"
                            placeholder="25"
                            value={get(vehicleInfo, 'terminal.odometer', '')}
                            required
                            type="number"
                            onChange={(e) => {
                              setError(null);
                              updateVehicleInfo('terminal.odometer',
                                  parseFloat(e.target.value));
                            }}
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <div
                          className="text-neutral-800 text-lg font-medium break-words mb-3 w-full">
                        Speed Limit
                      </div>
                      <div className="relative">
                        <input
                            className="w-full bg-white rounded-md shadow border border-neutral-200 p-4 text-neutral-800 text-base font-normal"
                            placeholder="25"
                            value={get(vehicleInfo, 'speed_limit', '')}
                            required
                            type="number"
                            onChange={(e) => {
                              setError(null);
                              updateVehicleInfo('speed_limit', parseInt(e.target.value));
                            }}
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <div
                          className="text-neutral-800 text-lg font-medium break-words mb-3 w-full">
                        Vehicle Type
                      </div>
                      <div className="relative">
                        <select
                            className="w-full bg-white rounded-md shadow border border-neutral-200 p-4 text-neutral-800 text-base font-normal"
                            required
                            value={get(vehicleInfo, 'type', '')}
                            onChange={(e) => {
                              setError(null);
                              updateVehicleInfo('type', e.target.value);
                            }}
                        >
                          <option value="car">Car</option>
                          <option value="bike">Bike</option>
                          <option value="bus">Bus</option>
                          <option value="truck">Truck</option>
                          <option value="van">Van</option>
                        </select>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div
                          className="text-neutral-800 text-lg font-medium break-words mb-3 w-full">
                        Vehicle Group
                      </div>
                      <div className="relative">
                        <input
                            className="w-full bg-white rounded-md shadow border border-neutral-200 p-4 text-neutral-800 text-base font-normal"
                            placeholder="Bikes"
                            value={get(vehicleInfo, 'group', '')}
                            required
                            type="text"
                            onChange={(e) => {
                              setError(null);
                              updateVehicleInfo('group', e.target.value);
                            }}
                        />
                      </div>
                    </div>
                    {[
                      'teltonika-fmb120',
                      'teltonika-fmb125',
                      'teltonika-fmb920'].includes(vehicleInfo.modal) && (<>
                      <div className="mt-4">
                        <div
                            className="text-neutral-800 text-lg font-medium break-words mb-3 w-full">
                          Secondary Engine
                        </div>
                        <div className="relative">
                          <select
                              className="w-full bg-white rounded-md shadow border border-neutral-200 p-4 text-neutral-800 text-base font-normal"
                              required
                              value={get(vehicleInfo, 'terminal.engine_type',
                                  '')}
                              onChange={(e) => {
                                setError(null);
                                updateVehicleInfo('terminal.engine_type',
                                    e.target.value);
                              }}
                          >
                            <option value="none">None</option>
                            <option value="ac">AC</option>
                            <option value="engine">Engine</option>
                          </select>
                        </div>
                      </div>
                      <div className="mt-4">
                        <div
                            className="text-neutral-800 text-lg font-medium break-words mb-3 w-full">
                          Fuel Tank Capacity
                        </div>
                        <div className="relative">
                          <input
                              className="w-full bg-white rounded-md shadow border border-neutral-200 p-4 text-neutral-800 text-base font-normal"
                              placeholder="25"
                              value={get(vehicleInfo, 'fuel_tank_capacity', '')}
                              required
                              type="number"
                              onChange={(e) => {
                                setError(null);
                                updateVehicleInfo('fuel_tank_capacity',
                                    parseFloat(e.target.value));
                              }}
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <div
                            className="text-neutral-800 text-lg font-medium break-words mb-3 w-full">
                          Fuel Type
                        </div>
                        <div className="relative">
                          <select
                              className="w-full bg-white rounded-md shadow border border-neutral-200 p-4 text-neutral-800 text-base font-normal"
                              required
                              value={get(vehicleInfo, 'fuel_type', '')}
                              onChange={(e) => {
                                setError(null);
                                updateVehicleInfo('fuel_type', e.target.value);
                              }}
                          >
                            <option value="petrol">Petrol</option>
                            <option value="diesel">Diesel</option>
                          </select>
                        </div>
                      </div>
                      <div className="mt-4">
                        <div
                            className="text-neutral-800 text-lg font-medium break-words mb-3 w-full">
                          Fuel Sensor
                        </div>
                        <div className="relative">
                          <select
                              className="w-full bg-white rounded-md shadow border border-neutral-200 p-4 text-neutral-800 text-base font-normal"
                              required
                              value={get(vehicleInfo, 'fuel_calculation_type',
                                  '')}
                              onChange={(e) => {
                                setError(null);
                                updateVehicleInfo('fuel_calculation_type',
                                    e.target.value);
                              }}
                          >
                            <option value="none">None</option>
                            <option value="litre">Litre</option>
                            <option value="voltage">Voltage</option>
                          </select>
                        </div>
                      </div>
                      {vehicleInfo.fuel_calculation_type === 'voltage' && (
                          <div className="mt-4">
                            <div
                                className="text-neutral-800 text-lg font-medium break-words mb-3 w-full">
                              Calibration Values
                            </div>
                            <div className="relative">
                              <div className="grid grid-cols-4 gap-2">
                                <div>
                                  <input
                                      className="w-full bg-white rounded-md shadow border border-neutral-200 p-2 text-neutral-800 text-base font-normal"
                                      placeholder="25"
                                      value={get(vehicleInfo,
                                          'fuel_calculation_values.a0', '')}
                                      required
                                      type="number"
                                      onChange={(e) => {
                                        setError(null);
                                        updateVehicleInfo(
                                            'fuel_calculation_values.a0',
                                            parseFloat(e.target.value));
                                      }}
                                  />
                                </div>
                                <div>
                                  <input
                                      className="w-full bg-white rounded-md shadow border border-neutral-200 p-2 text-neutral-800 text-base font-normal"
                                      placeholder="25"
                                      value={get(vehicleInfo,
                                          'fuel_calculation_values.a1', '')}
                                      required
                                      type="number"
                                      onChange={(e) => {
                                        setError(null);
                                        updateVehicleInfo(
                                            'fuel_calculation_values.a1',
                                            parseFloat(e.target.value));
                                      }}
                                  />
                                </div>
                                <div>
                                  <input
                                      className="w-full bg-white rounded-md shadow border border-neutral-200 p-2 text-neutral-800 text-base font-normal"
                                      placeholder="25"
                                      value={get(vehicleInfo,
                                          'fuel_calculation_values.a2', '')}
                                      required
                                      type="number"
                                      onChange={(e) => {
                                        setError(null);
                                        updateVehicleInfo(
                                            'fuel_calculation_values.a2',
                                            parseFloat(e.target.value));
                                      }}
                                  />
                                </div>
                                <div>
                                  <input
                                      className="w-full bg-white rounded-md shadow border border-neutral-200 p-2 text-neutral-800 text-base font-normal"
                                      placeholder="25"
                                      value={get(vehicleInfo,
                                          'fuel_calculation_values.a3', '')}
                                      required
                                      type="number"
                                      onChange={(e) => {
                                        setError(null);
                                        updateVehicleInfo(
                                            'fuel_calculation_values.a3',
                                            parseFloat(e.target.value));
                                      }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                      )}
                    </>)}
                  </div>
                </fieldset>
                <div className="mt-12 text-center">
                  <button
                      type="submit" disabled={loading}
                      className=" py-2 px-5 bg-blue-950 rounded-3xl text-white text-lg font-bold md:w-4/12 w-full">
                    Save Vehicle
                  </button>
                </div>
                {error && (
                    <div className="text-red-500 text-center mt-2">
                      {error}
                    </div>
                )}

              </div>
            </form>
          </div>
        </div>
        {loading && <Loading/>}
        <Drawer/>
      </div>
  );
}
