import React from 'react';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';

import {ProtectedRoute} from './ProtectedRoute';
import Login from '../pages/Login';
import Vehicles from '../pages/Vehicles';
import Users from '../pages/Users';
import EditProfile from '../pages/EditProfile';
import AddVehicle from '../pages/AddVehicle';

const Routes = () => {
  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: '/',
      element: <ProtectedRoute/>, // Wrap the component in ProtectedRoute
      children: [
        {
          path: '',
          element: <Vehicles/>,
        },
        {
          path: '/users',
          element: <Users/>,
        },
        {
          path: '/add-vehicle',
          element: <AddVehicle/>,
        },
        {
          path: '/edit-profile',
          element: <EditProfile/>,
        },
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    {
      path: '/login',
      element: <Login/>,
    },
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...routesForNotAuthenticatedOnly,
    ...routesForAuthenticatedOnly,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router}/>;
};

export default Routes;
