import {useDispatch, useSelector} from 'react-redux';
import React, {useCallback, useEffect, useState} from 'react';
import {MdOutlineMenu} from 'react-icons/md';

import DesktopHeader from '../components/DesktopHeader';
import axios from '../axios';
import {
  updateLoading,
  updateShowDrawerMenu,
} from '../redux/slices/generalSlice';
import Loading from '../components/Loading';
import Drawer from '../components/Drawer';
import DataTable from 'react-data-table-component';
import {get} from '../helpers';
import CreateUserPopup from '../components/CreateUserPopup';

export default function Users() {
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortDirection, setSortDirection] = useState('none');
  const [sortField, setSortField] = useState('none');

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.general.loading);
  const fetchUsers = useCallback(
      async (page, perPage, sortColumn = 'none', sortDirection = 'none') => {
        dispatch(updateLoading(true));

        const response = await axios.get(
            `/admin/users?page=${page}&per_page=${perPage}&sort_column=${sortColumn}&sort_direction=${sortDirection}`);
        const users = get(response, 'users', []);
        const count = get(response, 'count', 0);
        // console.log(response);

        setData(users);
        setTotalRows(count);
        dispatch(updateLoading(false));
      }, [dispatch]);

  useEffect(() => {
    fetchUsers(page, perPage, sortField, sortDirection).then(r => {
    });
  }, [fetchUsers, page, perPage, sortField, sortDirection]);

  const columns = [
    {
      name: 'Display Name',
      selector: row => get(row, 'display_name', 'unknown'),
      sortable: true,
      sortField: 'display_name',
    },
    {
      name: 'Username',
      selector: row => get(row, 'username', 'unknown'),
      sortable: true,
      sortField: 'username',
    },
    {
      name: 'Role',
      selector: row => get(row, 'role', '-'),
    },
    {
      name: 'Contact Number',
      selector: row => get(row, 'contact_number', '-'),
    },
    {
      name: 'Note',
      selector: row => get(row, 'note', '-'),
    },
    {
      name: 'Address',
      selector: row => get(row, 'address', '-'),
    },
    {
      name: 'Created At',
      selector: row => {
        const createdAt = get(row, 'created_at', new Date().toISOString());
        if (!createdAt) {
          return '-';
        }
        return new Date(createdAt).toLocaleDateString();
      },
      sortable: true,
      sortField: 'created_at',
    },
    {
      name: 'Updated At',
      selector: row => {
        const updatedAt = get(row, 'updated_at', new Date().toISOString());
        if (!updatedAt) {
          return '-';
        }
        return new Date(updatedAt).toLocaleDateString();
      },
      sortable: true,
      sortField: 'updated_at',
    },
  ];

  return (
      <div className="flex md:flex-row flex-col h-full relative">
        <div className="bg-blue-950 w-16 md:block hidden">
          <DesktopHeader/>
        </div>
        <div>
          <div
              className="w-full bg-white shadow md:hidden block flex justify-between items-center">
            <button
                className="cursor-pointer px-2 py-4 w-12 z-10"
                onClick={() => {
                  dispatch(updateShowDrawerMenu(true));
                }}
            >
              <MdOutlineMenu size={24} color="#001C6F"/>
            </button>
            <div className="w-full z-0 absolute">
              <div className="text-blue-950 text-base font-bold text-center">
                Users
              </div>
            </div>
            <button
                className="px-4 py-2 mr-2 z-10 ml-5 bg-blue-950 rounded-2xl text-center text-white font-normal text-sm font-bold"
                onClick={() => {
                  setShowAddPopup(true);
                }}
            >
              Add +
            </button>
          </div>
        </div>
        <div className="flex-1 relative p-5 table-listing">
          <div
              className="text-blue-950 text-xl font-bold md:mb-5 md:block hidden">
            Users

            <button
                className="px-4 py-1 mr-2 z-10 ml-5 bg-blue-950 rounded-2xl text-center text-white font-normal text-sm font-bold"
                onClick={() => {
                  setShowAddPopup(true);
                }}
            >
              Add New +
            </button>
          </div>
          <DataTable
              fixedHeader={true}
              columns={columns}
              data={data}
              progressPending={loading}
              pagination
              paginationServer
              sortServer
              onSort={(column, sortDirection) => {
                setSortField(column.sortField);
                setSortDirection(sortDirection);
              }}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={(newPerPage) => {
                setPerPage(newPerPage);
              }}
              onChangePage={(page) => {
                setPage(page);
              }}
          />
        </div>
        {loading && <Loading/>}
        <CreateUserPopup
            show={showAddPopup}
            onClose={() => {
              setShowAddPopup(false);
              fetchUsers(page, perPage, sortField, sortDirection).then(r => {
              });
            }}
        />
        <Drawer/>
      </div>
  );
}
