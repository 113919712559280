import {createSlice} from '@reduxjs/toolkit';

export const generalSlice = createSlice({
  name: 'general',
  initialState: {
    loading: false,
    showDrawerMenu: false,
    showAddVehiclePopup: false,
  },
  reducers: {
    updateLoading: (state, action) => {
      state.loading = action.payload;
    },
    updateShowDrawerMenu: (state, action) => {
      state.showDrawerMenu = action.payload;
    },
    updateShowAddVehiclePopup: (state, action) => {
      state.showAddVehiclePopup = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateShowDrawerMenu,
  updateLoading,
  updateShowAddVehiclePopup
} = generalSlice.actions;

export default generalSlice.reducer;
